<template>
  <div>
    <div v-show="!isNull">
      <md-scroll-view class="content"
                      ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMore">
        <div>
          <div class="item"
               v-for="(item,index) in myCardList"
               :key="index">
            <div @click="toCardshare(item.sno)">
              <div class="left">
                <img :src="ossurl+item.pichttp" />
              </div>
              <div class="right">
                <span class="title">为您送上祝福</span>
                <span class="look">浏览({{ item.ydnum }})</span>
                <span class="time">{{ item.fbtime }}</span>
              </div>
            </div>
            <div class="delete"
                 :style="`border:1px solid ${colorprimary};color:${colorprimary}`"
                 @click="deletesc(item.sno)">删除</div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="loading" />
      </md-scroll-view>
    </div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
  </div>
</template>
<script>
import { selectPage, deletesc } from '@/api/abt/customerOperation/common'
import { getStorage } from '@/lib/util'
import { Toast } from 'mand-mobile'
import loadMore from '@/mixins/loadmore'
export default {
  mixins: [loadMore],
  data () {
    return {
      user: '',
      ossurl: '',
      height: '',
      pageNo: 1,
      size: 12,
      total: 0,
      totalPage: 0,
      loading: false,
      myCardList: [],
      isNull: true,
      colorprimary: ''
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.height = document.body.clientHeight
    this.getData()
  },
  mounted () {
    document.querySelector('.content').style.height = `${this.height}px`
  },
  methods: {
    async getData (isInit = true) {
      let { pageNo, size } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        page: this.pageNo,
        size,
        flag: '1',
        btagcode: '4',
        stagcode: ''
      }
      let res = await selectPage(data)
      this.myCardList = isInit ? res.data.data.rows : this.myCardList.concat(res.data.data.rows)
      if (this.myCardList.length == 0) {
        this.isNull = true
      } else {
        this.isNull = false
      }
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    },
    deletesc (val) {
      deletesc({ smo: val }).then(res => {
        Toast.succeed('删除成功')
        this.getData()
      })
    },
    toCardshare (val) {
      this.$router.push(
        { name: 'cardshare', query: { sno: val, empno: this.user.empno, suserid: this.user.userid } }
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
.content {
  background-color: #f3f3f3;
  overflow-y: auto;
}

.item {
  padding: 30px 30px;
  margin-bottom: 30px;
  background-color: #fff;
  position: relative;

  .left {
    img {
      width: 200px;
      height: 350px;
    }
  }

  .right {
    .title {
      font-size: 43px;
      font-weight: 800;
      position: absolute;
      top: 50px;
      left: 300px;
    }

    .look {
      font-size: 40px;
      position: absolute;
      top: 320px;
      left: 300px;
    }

    .time {
      font-size: 40px;
      position: absolute;
      top: 330px;
      right: 50px;
    }
  }
}

.delete {
  position: absolute;
  top: 50px;
  right: 50px;
  border: 1px solid color-primary;
  color: color-primary;
  padding: 7px 30px;
  border-radius: 10px;
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}
</style>
